import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetStdRestDetail } from 'src/app/_Models/AD/ADSTD/ADSTD0301/getStdRestDetail';
import { GetStdRestList } from 'src/app/_Models/AD/ADSTD/ADSTD0301/getStdRestList';
import { GetStdRestListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0301/getStdRestListInput';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetSTDStatusList } from 'src/app/_Models/getSTDStatusList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { CheckType } from 'src/app/_SharedFunctions/checkType';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSTD0301',
    templateUrl: './ADSTD0301.component.html',
    styleUrls: ['./ADSTD0301.component.css']
})
export class ADSTD0301Component implements OnInit {
    isNewData = false;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    eduList!: GetEduList[];
    stdEduList!: GetEduList[];
    deptList!: GetDeptList[];
    stdDeptList!: GetDeptList[];
    classList!: GetClassList[];
    stdClassList!: GetClassList[];
    sTDStatusList!: GetSTDStatusList[];
    initialData = new InitialData(this.http);
    restReason!: GetSelectList[];
    checkType = new CheckType();
    getStdRestListInput: GetStdRestListInput = {
        RestYear: '',
        RestTerm: '',
        Approve: '',
        EduNo: '',
        DeptNo: '',
        Class: '',
        Status: '',
        IDNo: '',
        StdNo: '',
        StdName: ''
    };
    stdRestList!: GetStdRestList[];
    stdRestDetail!: GetStdRestDetail;
    bsConfig = environment.bsDatePickerConfig;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private systemService: SystemService,
        private localeService: BsLocaleService) { }

    ngOnInit(): void {
        this.localeService.use('zh-cn');
        this.getYears();
        this.getEduList(false);
        this.getSTDStatusList();
        this.getRestReason();
    }

    getRestReason() {
        this.systemService.getRestReason().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.restReason = response;
        });
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    getEduList(detail: boolean) {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (detail) {
                this.stdEduList = response;
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string, detail: boolean) {
        this.systemService.getDeptList(edu, '3', this.getStdRestListInput.RestYear, this.getStdRestListInput.RestTerm, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (detail) {
                this.stdDeptList = response;
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getClassList(edu: string, dept: string, detail: boolean) {
        this.systemService.getClassList(edu, dept, '3', this.getStdRestListInput.RestYear, this.getStdRestListInput.RestTerm, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            if (detail) {
                this.stdClassList = response;
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))
    }

    getSTDStatusList() {
        this.systemService.getSTDStatusList().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.sTDStatusList = response;
        }, error => this.toastr.error(error.error))
    }


    getStdRestList() {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_01/Get_StdRest_List", this.getStdRestListInput).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdRestList = []
                return;
            }
            this.stdRestList = response;
        })
    }

    getStdRestDetail(datakey: string, template: TemplateRef<any>, getStd: boolean) {
        if (!getStd) {
            this.isNewData = false;
            this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_01/Get_StdRest_Detail", { DataKey: datakey }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.stdRestDetail = response;
                this.stdRestDetail.StdName = this.stdRestDetail.StdName;
                this.stdRestDetail.RestClassName = this.stdRestDetail.RestClassName;
                this.stdRestDetail.ApplyDate = this.stdRestDetail.ApplyDate;
                this.stdRestDetail.Memo = this.stdRestDetail.Memo;
                this.stdRestDetail.RegiName = this.stdRestDetail.RegiName;
                this.stdRestDetail.CerSchNo = this.stdRestDetail.CerSchNo;
                let modalConfig = {
                    backdrop: true,
                    ignoreBackdropClick: true,
                    class: 'modal-xl'
                };

                this.modalRef = this.modalService.show(template, modalConfig);
            })
        }
        else {

            if (datakey == this.stdRestDetail.StdNo && this.stdRestDetail.StdName != '') {
                return;
            }

            this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_01/Get_StdRest_Detail", { DataKey: datakey }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.stdRestDetail = response;
                this.stdRestDetail.StdName = this.stdRestDetail.StdName;
                this.stdRestDetail.RestClassName = this.stdRestDetail.RestClassName;
                this.stdRestDetail.ApplyDate = this.stdRestDetail.ApplyDate;
                this.stdRestDetail.Memo = this.stdRestDetail.Memo;
                this.stdRestDetail.RegiName = this.stdRestDetail.RegiName;
                this.stdRestDetail.CerSchNo = this.stdRestDetail.CerSchNo;
            })
        }

    }

    newData(template: TemplateRef<any>) {
        this.isNewData = true;
        this.stdRestDetail = {
            DataKey: '',
            RestYear: '',
            RestTerm: '',
            StdNo: '',
            StdName: '',
            RestClassName: '',
            Delay: '',
            RestNo: '',
            ApplyDate: '',
            EndYear: '',
            EndTerm: '',
            TermCount: '',
            Memo: '',
            ApproveDate: '',
            Approve: '',
            RegiName: '',
            CerSchNo: '',
            Record_No:'',
            TotalTermCount: ''
        };

        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }



    caculate(years: string, term: string, termCount: string) {

        if (years == '' || term == '' || termCount == '') {
            return;
        }

        if (this.checkType.CheckIsNumber(termCount)) {
            this.toastr.info("休學期數請填入數字");
            this.stdRestDetail.TermCount = '';
            return;
        }

        var intTermCount = parseInt(termCount);
        var iYear;
        var iTerm;

        if (intTermCount % 2 == 0) {
            iYear = intTermCount / 2;
            iTerm = parseInt(term);
        }
        else {
            iYear = (intTermCount - 1) / 2;
            iYear += (term == "2") ? 1 : 0;
            iTerm = (term == "1") ? "2" : "1";
        }

        if (String(parseInt(years) + iYear).length == 2) {
            this.stdRestDetail.EndYear = '0' + String(parseInt(years) + iYear);
        }
        else {
            this.stdRestDetail.EndYear = String(parseInt(years) + iYear);
        }

        this.stdRestDetail.EndTerm = iTerm.toString();
    }

    getStdRestModify() {
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_01/Get_StdRest_Modify", this.stdRestDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.modalRef.hide();
        })
    }

    getStdRestDelete() {

        Swal.fire({
            title: `確定刪除學生-${this.stdRestDetail.StdName}?`,
            confirmButtonText: '確定',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: '取消'
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_01/Get_StdRest_Delete", { DataKey: this.stdRestDetail.DataKey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Warning);
                        this.stdRestList = [];
                        return;
                    }
                    this.getSTDStatusList();
                    this.toastr.success("刪除成功");
                    this.modalRef.hide();
                })
            }
        })
    }

}
